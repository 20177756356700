<template>
  <UCard
    :ui="{
      base: 'h-dvh flex flex-col p-0',
      rounded: 'rounded-none',
      body: {
        base: 'flex-1 overflow-y-auto ',
      },
      footer: {
        base: 'mt-auto',
      },
    }"
  >
    <template
      v-if="$slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <slot />
    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </UCard>
</template>
